import React from 'react';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';

const SelectProductInput = ({ label, values, selectedIndex, onChange }) => {
    return (
        <div>
            <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-small-bottom">
                <div>
                    <label className="uk-form-label uk-display-block">
                        {label}:
                    </label>
                </div>
                <div className="uk-width-2-3@m">
                    <div className="uk-form-controls">
                        <select
                            id="product"
                            className="uk-select"
                            value={selectedIndex >= 0 ? selectedIndex : ''}  // Set the current selection by index
                            onChange={onChange}
                        >
                            <option value="">-- Select --</option>
                            {values.map((value, index) => (
                                <option key={index} value={index}>  {/* Using the index as value */}
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectProductInput;
