import React, { useEffect } from 'react';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';
import './dimensionsInput.css';

const DimensionsInput = ({ label, unit, isRafter, values, onChange }) => {
    const handleInputChange = (e) => {
        const { id, value } = e.target;

        // Only allow whole numbers (empty input is also allowed)
        if (/^\d*$/.test(value)) {
            onChange({ target: { name: id, value: value } });
        }
    };

    const handleKeyDown = (e) => {
        const invalidChars = ['e', 'E', '+', '-'];

        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    // Automatically set the first option as default if the value is 0 or missing
    useEffect(() => {
        if (isRafter && values.RafterSpacingCB === 0) {
            onChange({ target: { name: 'RafterSpacingCB', value: '400' } });
        } else if (!isRafter && values.FloorJoistCentresEC === 0) {
            onChange({ target: { name: 'FloorJoistCentresEC', value: '400' } });
        }
    }, [isRafter, values, onChange]);

    return (
        <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-small-bottom">
            <div className="uk-first-column">
                <label className="uk-form-label uk-display-block">
                    {label}:
                </label>
            </div>

            <div className="dimension-input-container uk-width-2-3@m">
                <div className="input-container">
                    <div className="uk-flex uk-flex-middle uk-grid uk-grid-collapse">
                        <div className="uk-width-expand">
                            <input
                                id={`${isRafter ? 'RafterWidthCB' : 'FloorJoistWidthEC'}`}
                                value={
                                    values.RafterWidthCB === 0 || values.FloorJoistWidthEC === 0
                                        ? ''
                                        : `${isRafter ? values.RafterWidthCB : values.FloorJoistWidthEC}`
                                }
                                type="number"
                                min={0}
                                inputMode="numeric"
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                className="uk-input"
                                placeholder="Width"
                            />
                        </div>
                        <div className="uk-width-auto uk-text-meta">{unit}</div>
                    </div>
                </div>

                <div className="input-container">
                    <div className="uk-flex uk-flex-middle uk-grid uk-grid-collapse">
                        <div className="uk-width-expand">
                            <input
                                id={`${isRafter ? 'RafterDepthCB' : 'FloorJoistDepthEC'}`}
                                value={
                                    values.RafterDepthCB === 0 || values.FloorJoistDepthEC === 0
                                        ? ''
                                        : `${isRafter ? values.RafterDepthCB : values.FloorJoistDepthEC}`
                                }
                                type="number"
                                min={0}
                                inputMode="numeric"
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                className="uk-input"
                                placeholder="Depth"
                            />
                        </div>
                        <div className="uk-width-auto uk-text-meta">{unit}</div>
                    </div>
                </div>

                <div className="input-container">
                    {isRafter ? (
                        <select
                            id="RafterSpacingCB"
                            value={values.RafterSpacingCB || '400'} // Default to 400 if value is 0
                            onChange={handleInputChange}
                            className="uk-select uk-width-1-1"
                        >
                            <option value="400">400mm</option>
                            <option value="450">450mm</option>
                            <option value="600">600mm</option>
                        </select>
                    ) : (
                        <select
                            id="FloorJoistCentresEC"
                            value={values.FloorJoistCentresEC || '400'} // Default to 400 if value is 0
                            onChange={handleInputChange}
                            className="uk-select uk-width-1-1"
                        >
                            <option value="400">400mm</option>
                            <option value="600">600mm</option>
                        </select>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DimensionsInput;