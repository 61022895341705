import React from 'react';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';

const TextInput = ({ label, placeholder, unit, value, onChange, name }) => {
    // Function to handle input change and enforce whole positive numbers
    const handleInputChange = (e) => {
        const { value } = e.target;

        // Check if the input is a valid whole positive number
        const wholeNumber = /^\d+$/; // Regular expression to match whole numbers

        if (value === '' || wholeNumber.test(value)) {
            // Only allow the change if the value is empty or a whole positive number
            onChange({ target: { name: name, value: value } }); // Call onChange with the correct format
        }
    };

    return (
        <div>
            <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-small-bottom">
                <div className="uk-first-column">
                    <label className="uk-form-label uk-display-block">
                        {label}:
                    </label>
                </div>
                <div className="uk-width-2-3@m">
                    <div className="uk-flex-middle uk-grid uk-grid-small">
                        <div className="uk-width-expand uk-first-column">
                            <input
                                id="slab-thickness"
                                type="number"
                                placeholder={placeholder}
                                className="uk-input"
                                value={value} // Bind the value to the input
                                onChange={handleInputChange} // Handle the input change
                                min="1" // Enforce a minimum value of 1
                                step="1" // Ensure step values are whole numbers
                            />
                        </div>
                        <div className="uk-width-auto uk-text-meta">{unit}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextInput;
