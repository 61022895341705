import React from 'react';
import 'uikit/dist/css/uikit.min.css';
import 'uikit/dist/js/uikit.min.js';

const SelectInput = ({ label, values, onChange, name, value }) => {
    // Handle change to send the correct value to the parent component
    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        onChange({ target: { name, value: selectedValue } });
    };

    // Check if values is an array or an object and adjust accordingly
    const isArray = Array.isArray(values);

    return (
        <div>
            <div className="uk-child-width-1-1 uk-child-width-1-3@m uk-flex-middle uk-grid uk-grid-small uk-margin-small-bottom">
                <div>
                    <label className="uk-form-label uk-display-block">
                        {label}:
                    </label>
                </div>
                <div className="uk-width-2-3@m">
                    <div className="uk-form-controls">
                        <select 
                            id={name} 
                            className="uk-select" 
                            value={value} 
                            onChange={handleSelectChange}
                        >
                            {/* Render -- Select -- only if 0 is not in the values array */}
                            {isArray && !values.includes(0) && <option value="0">-- Select --</option>}
                            {isArray ? (
                                values.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                ))
                            ) : (
                                Object.keys(values).map((key) => (
                                    <option key={key} value={values[key]}>
                                        {key}
                                    </option>
                                ))
                            )}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SelectInput;
