import React, { useState } from 'react';
import axios from 'axios';
import './UvalueCalculator.css';

const EmailQuote = ({ formData, productUrl, newQuote }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleEmailSubmit = async () => {
        setIsSubmitting(true);
        formData.MakePDF = 1;
        //console.log(formData);

        try {
            // Fetch uValue and downloadUrl from your existing API
            const apiUrl = process.env.NODE_ENV === 'production' 
                ? 'https://mannok.jpatl.com/api/getuvalue' 
                : '/api/getuvalue'; // Ensure proxy is configured in dev
    
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();
            //console.log(data);
            const { uvalue, downloadUrl } = data;

            // Send email using WordPress REST API
            await axios.post('https://uvalue-calculator.nimbus.sugarprojects.com/wp-json/email/v1/send-quote', {
                email,
                uValue: uvalue,
                url: downloadUrl, // Download URL
                productUrl,
                data,
                formData
            });

            formData.MakePDF = 0;

            // Dynamically generate the timestamp
            const timestamp = new Date().toLocaleString();

            // Generate a unique ID, you can use a library or a basic counter/UUID
            const uniqueId = Date.now(); // Example: using current timestamp as a unique ID

            // Create a responsive URL from productUrl
            const responsiveUrl = productUrl.replace(/.*\.com/, ''); // This removes everything up to and including '.com'

            var temp = { 
                id: uniqueId, 
                timestamp, 
                url: responsiveUrl // This will link to the stored quote
            }

            // Add the new quote to history using the provided `newQuote` function
            newQuote(temp);

            setMessage('U-Value has been sent successfully!');
        } catch (error) {
            setMessage('There was an error sending the U-Value. Please try again.');
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <div className="uk-margin-top uk-flex uk-width-1-1">
                <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="uk-input email-input"
                    disabled={isSubmitting}
                    style={{ width: '75%' }}
                />
                <button
                    className="desktop uk-button uk-button-secondary send-quote-button"
                    type="button"
                    onClick={handleEmailSubmit}
                    disabled={isSubmitting || !email}
                    style={{ width: '25%', height: '60px' }}
                >
                    {isSubmitting ? 'Sending...' : 'Send Quote'}
                </button>
                <button
                    className="mobile uk-button uk-button-secondary send-quote-button"
                    type="button"
                    onClick={handleEmailSubmit}
                    disabled={isSubmitting || !email}
                    style={{
                        width: '20%',
                        display: 'flex',          // Center content horizontally
                        alignItems: 'center',      // Center content vertically
                        justifyContent: 'center',   // Center content horizontally
                        border: "solid blue 0px"
                    }}
                >
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="currentColor" 
                        viewBox="0 0 36 36" 
                        strokeWidth="0" 
                        stroke="currentColor" 
                        style={{ height: '100%', width: 'auto', paddingTop: "4px" }}
                    >
                        <path d="M30.65 16.353c0.063-0.1 0.1-0.222 0.1-0.353s-0.037-0.252-0.102-0.356l0.002 0.003c-0.018-0.031-0.036-0.057-0.055-0.082l0.001 0.002c-0.059-0.081-0.132-0.149-0.217-0.199l-0.003-0.002c-0.016-0.010-0.023-0.028-0.040-0.036l-28.001-14c-0.098-0.050-0.213-0.079-0.335-0.079-0.414 0-0.75 0.336-0.75 0.75 0 0.107 0.022 0.209 0.063 0.301l-0.002-0.005 5.873 13.704-5.873 13.705c-0.038 0.087-0.060 0.188-0.060 0.294 0 0.414 0.336 0.75 0.75 0.75h0c0.001 0 0.002 0 0.003 0 0.121 0 0.236-0.030 0.336-0.082l-0.004 0.002 28.001-13.999c0.017-0.008 0.024-0.027 0.040-0.036 0.088-0.053 0.161-0.12 0.219-0.199l0.001-0.002c0.018-0.023 0.036-0.050 0.052-0.077l0.002-0.003zM3.496 3.587l23.326 11.663h-18.327zM8.495 16.75h18.328l-23.326 11.664z"></path>
                    </svg>
                </button>

            </div>
            {message && <p>{message}</p>}
        </div>
    );
};

export default EmailQuote;