import UvalueCalculator from './UvalueCalculator';
import React, { useEffect, useState } from 'react';

function App() {
    const [isOnline, setIsOnline] = useState(navigator.onLine); // Track online status

    useEffect(() => {
        const handleOffline = () => {
            setIsOnline(false); // Update state when going offline
        };

        const handleOnline = () => {
            setIsOnline(true); // Update state when going online
            // Optionally reload the page to refresh data
            // window.location.reload(); // Uncomment if you want to reload on going online
        };

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        // Check initial online status
        if (!navigator.onLine) {
            setIsOnline(false); // Initialize state if currently offline
        }

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };
    }, []);

    if (!isOnline) {
        return (
            <div className="offline-page" style={offlinePageStyle}>
                <h1 style={headingStyle}>You are currently offline</h1>
                <p style={paragraphStyle}>Please check your internet connection.</p>
            </div>
        );
    }

    return (
        <div className="App">
            <UvalueCalculator />
        </div>
    );
}

// Styles
const offlinePageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa',
    textAlign: 'center',
    color: '#343a40',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const headingStyle = {
    fontSize: '2em',
    margin: '10px 0',
};

const paragraphStyle = {
    fontSize: '1.2em',
};

export default App;
