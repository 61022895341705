import React, { useEffect, useState } from 'react';
import axios from 'axios';

const TechSupport = ({}) => {
    return (
        <section className="uk-section uk-section-primary uk-section-small uk-width-1-1@m">
            <div className="uk-container">
                <div className="uk-grid-margin uk-grid uk-grid-stack">
                    <div className="uk-width-1-1@m uk-first-column">
                        <h1 className="uk-margin-medium uk-text-center@m uk-text-left">
                            Need technical support?
                        </h1>
                        <div className="uk-margin uk-width-xlarge@m uk-margin-auto uk-text-center@m uk-text-left">
                            <p>
                                For help and advice on any of our products and how they might fit your needs please contact our Technical by Mannok team.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="uk-container uk-container-small uk-grid-margin">
                    <div className="uk-flex-middle uk-grid uk-grid-stack uk-margin-medium-left">
                        <div className="uk-width-1-1@m uk-first-column">
                            <div>
                                <div className="uk-child-width-auto@m uk-child-width-1-1 uk-grid">
                                    <div className="uk-first-column">
                                        <span className="uk-margin-small-right uk-icon">
                                            <svg version="1.1" meta="vk-icons-receiver" width="30" height="30" viewBox="0 0 20 20" ratio="1.5">
                                                <path fill="none" stroke="#000" strokeWidth="1.01" d="M6.189,13.611C8.134,15.525 11.097,18.239 13.867,18.257C16.47,18.275 18.2,16.241 18.2,16.241L14.509,12.551L11.539,13.639L6.189,8.29L7.313,5.355L3.76,1.8C3.76,1.8 1.732,3.537 1.7,6.092C1.667,8.809 4.347,11.738 6.189,13.611"></path>
                                            </svg>
                                        </span>
                                        <a href="tel:08000322122" className="uk-text-top">08000 322 122 (UK/NI)</a>
                                    </div>
                                    <div>
                                        <span className="uk-margin-small-right uk-icon">
                                            <svg version="1.1" meta="vk-icons-receiver" width="30" height="30" viewBox="0 0 20 20" ratio="1.5">
                                                <path fill="none" stroke="#000" strokeWidth="1.01" d="M6.189,13.611C8.134,15.525 11.097,18.239 13.867,18.257C16.47,18.275 18.2,16.241 18.2,16.241L14.509,12.551L11.539,13.639L6.189,8.29L7.313,5.355L3.76,1.8C3.76,1.8 1.732,3.537 1.7,6.092C1.667,8.809 4.347,11.738 6.189,13.611"></path>
                                            </svg>
                                        </span>
                                        <a href="tel:1800322122" className="uk-text-top">1800 322 122 (ROI)</a>
                                    </div>
                                    <div>
                                        <span className="uk-margin-small-right uk-icon">
                                            <svg version="1.1" meta="vk-icons-mail" width="30" height="30" viewBox="0 0 20 20" ratio="1.5">
                                                <polyline fill="none" stroke="#000" points="1.4,6.5 10,11 18.6,6.5"></polyline>
                                                <path d="M 1,4 1,16 19,16 19,4 1,4 Z M 18,15 2,15 2,5 18,5 18,15 Z"></path>
                                            </svg>
                                        </span>
                                        <a href="mailto:technical@mannokbuild.com" className="uk-text-top">technical@mannokbuild.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TechSupport;
