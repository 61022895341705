import React, { useEffect, useState } from 'react';
import axios from 'axios';

const GeneralInfo = ({}) => {
    return (
        <section className="uk-section uk-section-default">
            <div className="uk-container">
                <h2>Free, Online U-Value Calculator</h2>
                <p><strong>Our U-Value calculator is a free, online tool designed to help you calculate the required thickness of insulation needed to achieve your desired U-Value.</strong></p>
                <p>This calculator can be utilized for floor, wall, pitched roof, and flat roof insulation applications.</p>
                <p>You can also have a wide range of calculations emailed directly to your inbox.</p>

                <h3>The Information You Will Need</h3>
                <p>To complete your U-Value calculation, gather the following information:</p>

                <h3>Wall Insulation Calculations</h3>
                <ul>
                    <li>Full construction build-up of the wall from outside to inside</li>
                    <li>Type of wall construction (e.g., cavity wall, timber frame, steel frame)</li>
                    <li>The desired U-Value you want to achieve, if known</li>
                </ul>

                <h3>Floor Insulation Calculations</h3>
                <ul>
                    <li>Perimeter area ratio (total exposed perimeter in meters divided by the intended insulated floor area)</li>
                    <li>Floor construction type (e.g., suspended floor, solid ground floor)</li>
                    <li>Full construction make-up of the floor (e.g., 75mm screed, vapour control layer, 150mm insulation, ground floor slab)</li>
                    <li>The desired U-Value you want to achieve, if known</li>
                </ul>

                <h3>Roof Insulation Calculations</h3>
                <ul>
                    <li>Type of roof construction (e.g., pitched roof or flat roof)</li>
                    <li>Construction make-up of the roof, including joist/rafter information</li>
                    <li>The desired U-Value you want to achieve, if known</li>
                    <li>Whether the roof is a ventilated or unventilated system</li>
                </ul>

                <h3>Understanding U-Values</h3>
                <p>A U-Value measures the heat loss through a building element, also known as thermal transmittance. It indicates the rate at which heat transfers through a building element, expressed as W/m<sup>2</sup>K.</p>
                <p>U-Values help assess the effectiveness of a building's envelope in preventing heat loss, with lower U-values indicating less heat loss. Building Regulations stipulate a maximum permitted U-value for each element that must be met or improved upon.</p>
                <p>Calculating U-Values can be complex; however, our U-Value calculator simplifies this process, allowing you to quickly determine the U-Values for your specific construction project.</p>

                <h3>What Thickness of Insulation Should I Use?</h3>
                <p>Utilize our U-Value calculator to determine the required insulation thickness for your application. You can adjust the insulation thickness within the calculator to meet the desired U-Value. Additionally, we have U-Value tables available for various floor, wall, and roof insulation applications, which can be found on each application page. These tables provide U-Values for a range of insulation thicknesses.</p>

                <h3>View our U-Value Tables:</h3>
                <div className="uk-margin-small uk-child-width-1-2@s uk-child-width-1-4@m uk-text-center uk-flex">
                    <div>
                        <a href="https://mannokbuild.com/insulation-boards/floor-insulation/" className="uk-padding-small uk-width-small">
                            <img src="https://uvalue-calculator.nimbus.sugarprojects.com/wp-content/uploads/2024/09/floor.png" alt="Floor Insulation" style={{ width: '130px' }}/>
                            <h3 className="uk-h4 el-title uk-padding-remove">Floor Insulation</h3>
                        </a>
                    </div>
                    <div>
                        <a href="https://mannokbuild.com/insulation-boards/pitched-roof/" className="uk-padding-small">
                            <img src="https://uvalue-calculator.nimbus.sugarprojects.com/wp-content/uploads/2024/09/pitched.png" alt="Pitched Roof Insulation" style={{ width: '130px' }}/>
                            <h3 className="uk-h4 el-title uk-padding-remove">Pitched Roof Insulation</h3>
                        </a>
                    </div>
                    <div>
                        <a href="https://mannokbuild.com/insulation-boards/flat-roof/" className="uk-padding-small">
                            <img src="https://uvalue-calculator.nimbus.sugarprojects.com/wp-content/uploads/2024/09/flat.png" alt="Flat Roof Insulation" style={{ width: '130px' }}/>
                            <h3 className="uk-h4 el-title uk-padding-remove">Flat Roof Insulation</h3>
                        </a>
                    </div>
                    <div>
                        <a href="https://mannokbuild.com/insulation-boards/wall-insulation/" className="uk-padding-small">
                            <img src="https://uvalue-calculator.nimbus.sugarprojects.com/wp-content/uploads/2024/09/wall.png" alt="Wall Insulation" style={{ width: '130px' }}/>
                            <h3 className="uk-h4 el-title uk-padding-remove">Wall Insulation</h3>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default GeneralInfo;
